import axios from "axios"

export const APP_NAME = "AXA Teaser"
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL
axios.defaults.baseURL = BASE_API_URL

export const COLORS = {
  black: "#131313",
  white: "#ffffff",
  gray: "#292929",
  lightGray: "#696969",
}
