import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom"
import { AppWrapper, AppHeader } from "layouts"
import { Teaser } from "pages"
import { RouteSegments } from "./segments"

export function AppRoutes() {
  function renderAllRoutes() {
    return (
      <Route
        element={<AppWrapper />}
        errorElement={<div>There's been an error!</div>}
      >
        <Route element={<AppHeader />}>
          <Route path={RouteSegments.Root} element={<Outlet />}>
            <Route index element={<Teaser />} />
          </Route>
          <Route path={RouteSegments.Teaser} element={<Outlet />}>
            <Route index element={<Teaser />} />
          </Route>
          <Route index element={<Navigate to={RouteSegments.Root} />} />
        </Route>
        <Route index element={<Navigate to={RouteSegments.Root} />} />
        <Route
          path={RouteSegments.Wildcard}
          element={<Navigate to={RouteSegments.Root} />}
        />
      </Route>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(renderAllRoutes())
  )

  return <RouterProvider router={router} />
}

export default AppRoutes
