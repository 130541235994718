import { Stack } from "@mantine/core"
import { Outlet } from "react-router-dom"

export function AppHeader() {
  return (
    <Stack>
      <Outlet />
    </Stack>
  )
}
