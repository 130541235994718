import { Outlet } from "react-router-dom"
import { COLORS } from "settings"

export function AppWrapper() {
  return (
    <div
      style={{
        backgroundColor: COLORS.black,
        color: COLORS.white,
        width: "100%",
        height: "100%",
      }}
    >
      <Outlet />
    </div>
  )
}
