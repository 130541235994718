import {
  Group,
  Image,
  Stack,
  Center,
  Text,
  AspectRatio,
  em,
  TextInput,
  Button,
} from "@mantine/core"
import HeroImage from "assets/AXA-MIRROR.jpg"
import LogoAnimated from "assets/LOGO-ANIMATION.gif"
import { useViewportSize, useMediaQuery } from "@mantine/hooks"
import { COLORS } from "settings"
import { ReactComponent as EmailSVG } from "assets/email.svg"
import { ReactComponent as SoundcloudSVG } from "assets/soundcloud.svg"
import { ReactComponent as InstagramSVG } from "assets/instagram.svg"
import { ReactComponent as XSVG } from "assets/X.svg"
import { ReactComponent as TikTokSVG } from "assets/TikTok.svg"
import { ReactComponent as GlobeSVG } from "assets/Globe.svg"
import { ReactComponent as HDSVG } from "assets/HD.svg"
import { useState } from "react"
import { BrowserUtils } from "utils"

export function Teaser() {
  const { height, width } = useViewportSize()
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const socialIconSize = isMobile ? 24 : 40
  const footerIconSize = isMobile ? 14 : 20

  const submitForm = (e: any) => {
    if (e) e.preventDefault()

    const portalId = "45291753"
    const formId = "7b4f7b6b-15f8-4251-9877-cfd4b782f8e0"

    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

    setLoading(true)

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
    }

    // These specific headers are required
    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setError(false)
      })
      .catch((err) => {
        setSuccess(false)
        setError(true)
        setLoading(false)
      })
  }

  function onClickSocial(url: string) {
    window.open(url, "_blank")
  }

  return (
    <Group gap={0} w={width} h={isMobile ? "100%" : height} align="flex-start">
      <AspectRatio
        ratio={1024 / 720}
        w={isMobile ? width : width / 2}
        h={isMobile ? height / 2 : height}
      >
        <Image src={HeroImage} fit="cover" />
      </AspectRatio>
      <Stack
        justify="center"
        align="center"
        flex={1}
        m={"auto"}
        mt={`${isMobile ? "xl" : "auto"}`}
        mb={`${isMobile ? "80px" : "auto"}`}
      >
        <Center mt={"xl"}>
          <AspectRatio
            ratio={400 / 200}
            w={isMobile ? 300 : 400}
            h={isMobile ? 150 : 200}
          >
            <Image src={LogoAnimated} fit="fill" />
          </AspectRatio>
        </Center>
        <Center mt={"xl"}>
          <Text
            style={{
              fontFamily: "Space Grotesk",
              fontSize: `${isMobile ? "18px" : "24px"}`,
            }}
          >
            MIRRORED IN PERFECT SYNCHRONICITY
          </Text>
        </Center>
        <Group
          align="flex-end"
          wrap="nowrap"
          w={"100%"}
          justify="center"
          p={isMobile ? "md" : "0"}
          mt={"sm"}
        >
          <TextInput
            label="Email"
            placeholder="contact@axamusic.net"
            variant="filled"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
            styles={{
              input: {
                outline: `2px solid ${COLORS.lightGray}`,
                backgroundColor: COLORS.black,
                color: COLORS.white,
              },
              root: {
                width: `${isMobile ? "60%" : "30%"}`,
              },
            }}
          />
          <Button
            color={COLORS.gray}
            loading={loading}
            onClick={submitForm}
            styles={{
              root: {
                "--button-hover": `${COLORS.lightGray}`,
                top: isMobile ? "0" : 3,
              },
            }}
            size={`${isMobile ? "sm" : "md"}`}
          >
            <Text c={COLORS.white} style={{ fontFamily: "Space Grotesk" }}>
              {success ? "SUBSCRIBED" : error ? "ERROR" : "SUBSCRIBE"}
            </Text>
          </Button>
        </Group>
        <Group
          align="center"
          w={"100%"}
          justify="center"
          gap={`${isMobile ? "50px" : "40px"}`}
          mt={"sm"}
        >
          <a href="mailto:contact@axamusic.net">
            <EmailSVG width={socialIconSize} height={socialIconSize} />
          </a>
          <SoundcloudSVG
            width={socialIconSize}
            height={socialIconSize}
            style={{ cursor: "pointer" }}
            onClick={() =>
              onClickSocial("https://soundcloud.com/axamusic_official")
            }
          />
          <InstagramSVG
            width={socialIconSize}
            height={socialIconSize}
            style={{ cursor: "pointer" }}
            onClick={() =>
              onClickSocial("https://www.instagram.com/axamusic_official/")
            }
          />
          <XSVG
            width={socialIconSize}
            height={socialIconSize}
            style={{ cursor: "pointer" }}
            onClick={() => onClickSocial("https://twitter.com/axamusic_")}
          />
          <TikTokSVG
            width={socialIconSize}
            height={socialIconSize}
            style={{ cursor: "pointer" }}
            onClick={() => onClickSocial("https://www.tiktok.com/@axamusic")}
          />
        </Group>
        <Group align="center" w={"100%"} justify="center" gap="12px">
          <GlobeSVG width={footerIconSize} height={socialIconSize} />
          <Text size={`${isMobile ? "12px" : "20px"}`}>
            AXA © {new Date().getFullYear()}
          </Text>
          <HDSVG width={footerIconSize} height={socialIconSize} />
        </Group>
      </Stack>
    </Group>
  )
}
