import React from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { MantineProvider, createTheme } from "@mantine/core"
import { AppRoutes } from "routes"
import "@mantine/core/styles.css"
import "@mantine/dates/styles.css"
import "./index.css"
import { COLORS } from "settings"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const theme = createTheme({
  fontFamily: "Open Sans",
  black: COLORS.black,
  white: COLORS.white,
})

function Entrypoint() {
  return (
    <MantineProvider theme={theme}>
      <AppRoutes />
    </MantineProvider>
  )
}

root.render(<Entrypoint />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
